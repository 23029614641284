export function getCircleCoordinates(centerX, centerY, radius, numPoints) {
    let points = {
      x: [],
      y: []
    };
  
    for (let i = 0; i <= numPoints; i++) {  
      let angle = 2 * Math.PI * i / numPoints;
      points.x.push(centerX + radius * Math.cos(angle));
      points.y.push(centerY + radius * Math.sin(angle));
    }
    
    return points;
}

export function getPoleCoordinates(zeta, omega) {
    if (zeta > 1) {
      return [-zeta*omega + Math.sqrt(Math.pow(zeta*omega, 2) - Math.pow(omega, 2)), 0, -zeta*omega - Math.sqrt(Math.pow(zeta*omega, 2) - Math.pow(omega, 2)), 0];
    }
    else if (zeta == 1) {
      return [-omega, 0, -omega, 0];
    }
    else if (zeta > -1){
      return [-zeta*omega, Math.sqrt(-Math.pow(zeta*omega, 2) + Math.pow(omega, 2)), -zeta*omega,-Math.sqrt(-Math.pow(zeta*omega, 2) + Math.pow(omega, 2))];
    }
    else if (zeta == -1) {
      return [omega, 0, omega, 0];
    }
    else {
    return [-zeta*omega + Math.sqrt(Math.pow(zeta*omega, 2) - Math.pow(omega, 2)), 0, -zeta*omega - Math.sqrt(Math.pow(zeta*omega, 2) - Math.pow(omega, 2)), 0];
    }
}