// -1 < zeta < 1
export function lowPassStepFunction(omega, t, zeta) {
    return (1 - Math.pow(Math.E, -omega*zeta*t)*(Math.cos(omega*Math.sqrt(1-zeta*zeta)*t) + (zeta/Math.sqrt(1-zeta*zeta))*Math.sin(omega*Math.sqrt(1-zeta*zeta)*t)));
}

// zeta = 1
export function criticallyDampedLowPassStepFunction(omega, t) {
    return (1 - Math.pow(Math.E, -omega*t) - omega*t*Math.pow(Math.E, -omega*t));
}

// zeta > 1
export function overDampedLowPassStepFunction(omega, t, zeta) {
    return 1 + (1/(2*(zeta + Math.sqrt(zeta*zeta-1))*(Math.sqrt(zeta*zeta-1))))*Math.pow(Math.E, -(zeta + Math.sqrt(zeta*zeta-1))*omega*t)
    - (1/(2*(zeta - Math.sqrt(zeta*zeta-1))*(Math.sqrt(zeta*zeta-1))))*Math.pow(Math.E, -(zeta - Math.sqrt(zeta*zeta-1))*omega*t);
}

// zeta < -1
export function underMinusOne(omega, t, zeta) {
    return overDampedLowPassStepFunction(omega, -t, -zeta);
}

// zeta = -1
export function minusOne(omega, t) {
    return criticallyDampedLowPassStepFunction(omega, -t);
}

export function lowPassStepFunctionChooser(zeta) {
    if (zeta < -1) {
        return underMinusOne;
    }
    else if (zeta == -1) {
        return minusOne;
    }
    else if ((-1 < zeta) && (1 > zeta)) {
        return lowPassStepFunction;
    }
    else if (zeta == 1) {
        return criticallyDampedLowPassStepFunction;
    }
    else if (zeta > 1) {
        return overDampedLowPassStepFunction;
    }
}   

export function lowPassStepFunctionResponse(zeta, omega) {
    let stepFunctionResponse = {
        x: [],
        y: []
    }

    let func = lowPassStepFunctionChooser(zeta);

    for (let i = -15/omega; i < 15/omega; i += 1/(omega*20)) {
        stepFunctionResponse.x.push(i);
        stepFunctionResponse.y.push(func(omega, i, zeta));
    }

    return stepFunctionResponse;
}

