<template>
  <div class="dropdown" :class="{ show: isOpen }" @click.stop="toggleDropdown">
    <button
      class="btn btn-primary dropdown-toggle"
      :style="{ width: dropdownWidth, height: dropdownHeight }"
      type="button"
      id="dropdownMenuButton"
      aria-expanded="false"
    >
      {{ displayedText }}
    </button>
    <ul
      class="dropdown-menu"
      :class="{ show: isOpen }"
      aria-labelledby="dropdownMenuButton"
      @click.stop="toggleDropdown"
    >
      <li
        v-for="(dropdownItem, index) in dropDownOptions"
        :key="index"
        @click="setButtonText(dropdownItem)"
      >
        <a class="dropdown-item" href="#">{{ dropdownItem }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  mounted() {
    // Add click event listener to the document body
    document.body.addEventListener('click', this.handleDocumentClick);
  },
  beforeUnmount() {
    // Remove the click event listener from the document body
    document.body.removeEventListener('click', this.handleDocumentClick);
  },
  props: {
    dropDownOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    dropdownWidth: {
      type: String,
      required: false,
      default: 'auto',
    },
    dropdownHeight: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
  data() {
    return {
      isOpen: false,
      displayedText: this.dropDownOptions[0],
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleDocumentClick() {
      // Close the dropdown if it's open
      if (this.isOpen) {
        this.toggleDropdown();
      }
    },
    setButtonText(buttonText) {
      this.displayedText = buttonText;
      this.$emit("item-selected", buttonText);
    }
  },
};
</script>

<style scoped>
.dropdown-menu.show {
  display: block;
}
</style>
