import { lowPassStepFunctionResponse } from "./step_function_response/lowpass-system-functions.js"
import { highPassStepFunctionResponse } from "./step_function_response/highpass-system-function.js";
import { bandPassStepFunctionResponse } from "./step_function_response/bandpass-system-fuctions.js";
import { bandStopStepFunctionResponse } from "./step_function_response/bandstop-system-functions.js";

export function functionStepFunctionResponse(filterType) {
    switch (filterType) {
        case "Low-pass filter":
            return lowPassStepFunctionResponse;
        case "High-pass filter":
            return highPassStepFunctionResponse; 
        case "Band-pass filter":
            return bandPassStepFunctionResponse;
        case "Band-stop filter":
            return bandStopStepFunctionResponse;
        default:
            return () => [0,1];
    }
}