import { highPassStepFunctionChooser } from "./highpass-system-function";
import { lowPassStepFunctionChooser } from "./lowpass-system-functions";

export function bandStopStepFunctionResponse(zeta, omega) {
    let stepFunctionResponse = {
        x: [],
        y: []
    }

    let func1 = highPassStepFunctionChooser(zeta);
    let func2 = lowPassStepFunctionChooser(zeta);

    for (let i = -15/omega; i < 15/omega; i += 1/(omega*20)) {
        stepFunctionResponse.x.push(i);
        stepFunctionResponse.y.push(func1(omega, i, zeta) + func2(omega, i, zeta));
    }

    return stepFunctionResponse;
}