<template>
  <div ref="plotContainer" class="myDiv"></div>
</template>

<script>
import Plotly from 'plotly.js-dist';
import { getPoleCoordinates, getCircleCoordinates } from "../utility/plot-utils.js";

export default {
  name: 'PlotPoles',
  props: {
    zeta: {
      type: Number,
      required: true,
    },
    omega: {
      type: Number,
      required: true,
    }
  },
  mounted() {
    this.renderChart();
  },
  watch: {
      zeta() {
        this.renderChart();
      },
      omega() {
        this.renderChart();
      }
  },
  methods: {
    renderChart() {
      // Just a placeholder for whatever your function calculation is
      const systemFunctionValue = getPoleCoordinates(this.zeta, this.omega); 

      // Update the plot
      const trace = {
          x: [systemFunctionValue[0], systemFunctionValue[2]],
          y: [systemFunctionValue[1], systemFunctionValue[3]],
          type: 'scatter',
          mode: 'markers',
          marker: {
              size: 12, // Size of the marker points
              color: 'black', // Color of the marker points
              symbol: 'x' // This sets the marker symbol to 'X'
            }
      };

      // Define the center and radius of the circle
      let centerX = 0;
      let centerY = 0;
      
      // Get points that define the circle
      let circlePoints = getCircleCoordinates(centerX, centerY, this.omega, 100);
      
      // Define the trace for the circle with the calculated points
      let circleTrace = {
        x: circlePoints.x,
        y: circlePoints.y,
        mode: 'lines', // Use lines mode to draw only the outline
        type: 'scatter', // Scatter is used for both points and lines
        name: 'Circle Outline'
      };
      
      // Define the layout of the plot
      let layout = {
        title: 'Poles and zeros plot',
        margin: {
                l: 30, // left margin
                r: 30, // right margin
                t: 30, // top margin
                b: 30  // bottom margin
        },
        width: 550,
        height: 400,
        showlegend: false,
        xaxis: {
          range: [-this.omega*1.1, this.omega*1.1],
          showline: true,  // Ensures that the axis line is shown
          zeroline: true,  // Ensures that the zero line is shown
          showgrid: false,  // Optionally, turn off the grid for clarity
          title: 'Re'
        },
        yaxis: {
          range: [-this.omega*1.1, this.omega*1.1],
          showline: true,  // Ensures that the axis line is shown
          zeroline: true,  // Ensures that the zero line is shown
          showgrid: false,  // Optionally, turn off the grid for clarity
          // Set the scale anchor to 'x' to use the x-axis as a reference
        scaleanchor: 'x',
        // Set the scaleratio to 1 to maintain a 1:1 aspect ratio
        scaleratio: 1,
        title: 'Im'
        }
      };

      let config = {
        staticPlot: true  // This disables all interactivity
      };

      let data = [circleTrace, trace];
      
      // Plot the circle outline
      Plotly.newPlot(this.$refs.plotContainer, data, layout, config);
      //Plotly.newPlot(this.$refs.plotContainer, [circleTrace], layout, config);
    }
  }
}
</script>

<style scoped>
/*
.plot-container {
  width: 100%;
  height: 100%;
}
*/
</style>
