<template>
  <div id="app">

    <nav>
      <DropdownMenu 
      :dropDownOptions="filterButtonOptions" 
      :dropdownWidth="filterButtonWidth" 
      :dropdownHeight="filterButtonHeight"
      @item-selected="updateStepFunctionResponse"/>
      
      <Slider v-model:value="zeta" name="Zeta:" :min="-5" :max="5"/>
      <Slider v-model:value="omega" name="Omega:" :min="1" :max="1000"/>
    </nav>

    <div class="canvas-container">
      <PlotPoles
      :zeta="zeta" 
      :omega="omega"/> 
      
      <PlotChart
      :zeta="zeta"
      :omega="omega" 
      :func="stepFunctionResponse"/>
    </div>

  </div>
</template>

<script>
import DropdownMenu from './components/DropdownMenu.vue';
import PlotChart from './components/plotting/PlotChart.vue';
import PlotPoles from './components/plotting/PlotPoles.vue';
import Slider from "./components/SliderBar.vue"
import { functionStepFunctionResponse } from "./components/utility/system_functions/step-function-chooser.js"

export default {
  name: 'App',
  components: {
    DropdownMenu,
    PlotChart,
    PlotPoles,
    Slider
  },
  data() {
    return {
      // Button variables for selecting filtertypes button
      filterButtonOptions: ["Low-pass filter", "High-pass filter", "Band-pass filter", "Band-stop filter"],
      filterButtonWidth: "150px",
      filterButtonHeight: "40px",
      selectedFiter: "Low-pass filter",

      // Slider variables
      zeta: 0,
      omega: 1,

      // Import function as part of the data object
      stepFunctionResponse: functionStepFunctionResponse("Low-pass filter"),
    }
  },
  methods: {
    updateStepFunctionResponse(selectedFilter) {
      this.selectedFiter = selectedFilter;
      this.stepFunctionResponse = functionStepFunctionResponse(selectedFilter);
    }
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
  
#app {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 10px;
  padding-top: 5px;
}

nav {
  display: flex;
  align-items: left;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
 /*  background-color: black ; Background color */
  border-radius: 5px; /* Optional: Rounded corners */
  color: balck;
}

.canvas-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 0px; 
}

/*
canvas {
  flex: 1;
  max-width: 45%;
  margin: 10px;
}
*/
</style>
