// -1 < zeta < 1
export function bandPassStepFunction(omega, t, zeta) {
    return (1/(omega * Math.sqrt(1-zeta*zeta))) * Math.pow(Math.E, -omega*zeta*t)*(Math.sin(omega*Math.sqrt(1-zeta*zeta)*t));
}

// zeta = 1
export function bandPassCriticallyDampedStepFunction(omega, t, zeta) {
    return (Math.pow(Math.E, -omega*zeta*t)*t);
}

// zeta < -1 and zeta > 1
export function bandPassOverDampedStepFunction(omega, t, zeta) {
    return (1/(omega * Math.sqrt(zeta*zeta - 1))) * Math.pow(Math.E, -omega*zeta*t)*(Math.sinh(omega*Math.sqrt(zeta*zeta - 1)*t));
}

function bandPassStepFunctionChooser(zeta) {
    if ((zeta < -1) || (zeta > 1)) {
        return bandPassOverDampedStepFunction;
    }
    else if ((-1 < zeta) && (1 > zeta)) {
        return bandPassStepFunction;
    }
    else if ((zeta == 1) || (zeta == -1)) {
        return bandPassCriticallyDampedStepFunction;
    }
}   

export function bandPassStepFunctionResponse(zeta, omega) {
    let stepFunctionResponse = {
        x: [],
        y: []
    }
    // console.log(zeta, omega)
    let func = bandPassStepFunctionChooser(zeta);

    for (let i = -15/omega; i < 15/omega; i += 1/(omega*20)) {
        stepFunctionResponse.x.push(i);
        stepFunctionResponse.y.push(func(omega, i, zeta));
    }

    return stepFunctionResponse;
}

