<template>
    <div class="slider-container">
        <label for="zeta">{{ name }}</label>

        <input
        type="range"
        :value="value"
        :min="min"
        :max="max"
        :step="0.1"
        @input="updateValue($event.target.value)">

        <span id="zeta-value">{{ value }}</span>
    </div>
</template>
  
<script>
  export default {
    name: 'SliderBar',
    props: {
      value: Number,
      min: Number,
      max: Number,
      name: Text,
    },
    methods: {
      updateValue(newValue) {
        this.$emit('update:value', Number(newValue));
      }
    }
  }
</script>

<style scoped>
.slider-container {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 40px; /* Width of slider */
  width: 100%;
  /* Temporary fix */
  padding-left: 10px;
  padding-right: 200px;
}

.slider-container input[type="range"] {
  flex: 1;
  margin-top: 6px;
}

.slider-container span {
  display: inline-block;
  margin-top: 2px;
}
</style>
  