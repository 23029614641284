// -1 < zeta < 1
export function highPassStepFunction(omega, t, zeta) {
    return (Math.pow(Math.E, -omega*zeta*t)*(Math.cos(omega*Math.sqrt(1-zeta*zeta)*t) - (zeta/Math.sqrt(1-zeta*zeta))*Math.sin(omega*Math.sqrt(1-zeta*zeta)*t)));
}

// zeta = 1
export function highPassCriticallyDampedStepFunction(omega, t) {
    return ((1 - omega*t)*Math.pow(Math.E, -omega*t));
}

// zeta < -1 and zeta > 1
export function highPassOverDampedStepFunction(omega, t, zeta) {
    return (Math.pow(Math.E, -omega*zeta*t)*(Math.cosh(omega*Math.sqrt(zeta*zeta - 1)*t) - (zeta/Math.sqrt(zeta*zeta - 1))*Math.sinh(omega*Math.sqrt(zeta*zeta - 1)*t)));
}

// zeta = -1
export function minusOne(omega, t) {
    return highPassCriticallyDampedStepFunction(omega, -t);
}

export function highPassStepFunctionChooser(zeta) {
    if (zeta < -1) {
        return highPassOverDampedStepFunction;
    }
    else if (zeta == -1) {
        return minusOne;
    }
    else if ((-1 < zeta) && (1 > zeta)) {
        return highPassStepFunction;
    }
    else if (zeta == 1) {
        return highPassCriticallyDampedStepFunction;
    }
    else if (zeta > 1) {
        return highPassOverDampedStepFunction;
    }
}   

export function highPassStepFunctionResponse(zeta, omega) {
    let stepFunctionResponse = {
        x: [],
        y: []
    }

    let func = highPassStepFunctionChooser(zeta);

    for (let i = -15/omega; i < 15/omega; i += 1/(omega*20)) {
        stepFunctionResponse.x.push(i);
        stepFunctionResponse.y.push(func(omega, i, zeta));
    }

    return stepFunctionResponse;
}

