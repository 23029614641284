<template>   
  <div ref="plotContainer" class="myDiv"></div>
</template>
  
<script>
import Plotly from 'plotly.js-dist';

export default {
  name: 'PlotChart',
  props: {
    zeta: {
      type: Number,
      required: true,
    },
    omega: {
      type: Number,
      required: true,
    },
    func: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    zeta() {
      this.renderChart();
    },
    omega() {
      this.renderChart();
    },
    func() {
      this.renderChart();
    },
  },
  methods: {  
    renderChart() {
      let response = this.func(this.zeta, this.omega)
      
      // Define the layout of the plot
      let stepFunctionResponse = {
        x: response.x,
        y: response.y,
        type: 'scatter',
        mode: 'lines',
        name: 'Step function Response'
      }

      let layout = {
        title: 'Step function response',
        showlegend: false,
        xaxis: {
          range: [-15/this.omega, 15/this.omega],
          showline: true,  // Ensures that the axis line is shown
          zeroline: true,  // Ensures that the zero line is shown
          showgrid: false,  // Optionally, turn off the grid for clarity
          title: 'Time'
        },
        yaxis: {
          range: [-4*1.1, 4*1.1],
          showline: true,  // Ensures that the axis line is shown
          zeroline: true,  // Ensures that the zero line is shown
          showgrid: false,  // Optionally, turn off the grid for clarity
          title: 'Amplitude'
        }
      }

      let config = {
        staticPlot: true  // This disables all interactivity
      };

      let data = [stepFunctionResponse];
      
      // Plot the circle outline
      Plotly.newPlot(this.$refs.plotContainer, data, layout, config);
    }
  }
};
</script>
  
<style scoped>
</style>  